
import { useStore } from 'vuex';
import { ref, computed} from 'vue';
import Sidebar from "@/layout/Sidebar.vue";
import store from "@/store";
  export default {
    computed: {
      store() {
        return store
      }
    },
    components: {Sidebar},
    setup() {
      const store = useStore();
      const isOpen = computed(() => 
        store.state.showSidebar
      );
      
      const siteInfo = computed(() => store.state.siteInfo);

      const date = new Date();
      const year = ref();
      const handleClick = () => {
        year.value = date.getFullYear();
        store.dispatch('closeSidebar');
      }

      const getCurrentUri = () => {
        // 获取当前所在页面的uri
        return window.location.pathname;
      }

      const handleMaskClick = () => {
        store.dispatch('closeSidebar');
      }

      return {
        isOpen,
        handleClick,
        siteInfo,
        year,
        getCurrentUri,
        handleMaskClick,
      }
    }
  }
