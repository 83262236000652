
import '../assets/iconfont.css';
import MyProfile from '@/components/MyProfile.vue';
import SidebarMobile from '@/layout/SidebarMobile.vue';
import SiteFooter from '@/components/SiteFooter.vue';
import Sidebar from "@/layout/Sidebar.vue";
import {useStore} from "vuex";
import store from "@/store";
import {computed, ref} from "vue"
export default {
  components: { MyProfile, SidebarMobile,SiteFooter, Sidebar},
  computed: {
    store() {
      return store
    }
  },
  setup() {
    const store = useStore();

    const handleOpen = () => {
      store.dispatch('openSidebar');

    };
    const isOpen = computed(() =>
        store.state.showSidebar
    );

    const isDarkMode = computed(
        () => store.getters.isDarkMode
    );

    let src = ref(`/fontmesh/index.html?isDarkMode=${isDarkMode.value}`);
    // 每次isDarkMode变化时刷新iframe
    store.watch(
        (state) => state.isDarkMode,
        () => {
          if(store.state.isDarkMode) {
            src.value = '/fontmesh/index.html?isDarkMode=true';
            refreshIframe(src);
          }else {
            src.value = '/fontmesh/index.html?isDarkMode=false';
            refreshIframe(src);
          }
        }
    );

    const refreshIframe = (src) => {
      // 获取iframe元素
      const iframe = document.querySelector('iframe');
      if(iframe != null) {
        iframe.contentWindow.location.reload();
      }
    }

    return {
      handleOpen,
      isOpen,
      src
    };
  }

}
