
import { ref,onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import '../assets/iconfont.css';
import MyProfile from '@/components/MyProfile.vue';
import SidebarMobile from '@/layout/SidebarMobile.vue';
import SiteFooter from '@/components/SiteFooter.vue';
export default {
  components: { MyProfile },
  setup() {
    const store = useStore();
    const date = new Date();
    const year = ref();
    year.value = date.getFullYear();
    const siteInfo = computed(
        () => store.state.siteInfo
    );

    return {
      siteInfo,
      year
    };
  }
}
