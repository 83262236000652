import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_loading_bar = _resolveComponent("loading-bar")!
  const _component_back_to_top = _resolveComponent("back-to-top")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_router_link, { to: "/" }),
    _createVNode(_component_router_view, null, {
      default: _withCtx(({Component}) => [
        (_openBlock(), _createBlock(_KeepAlive, null, [
          (_ctx.$route.meta.keepAlive)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), { key: 0 }))
            : _createCommentVNode("", true)
        ], 1024)),
        (!_ctx.$route.meta.keepAlive)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_loading_bar),
    _createVNode(_component_back_to_top)
  ]))
}